
<template>
<form autocomplete="off" class="">
    <div class="row g-lg-4 g-3">
        <div class="col-12">
            <div class="">
            <label>Select Columns </label>
            <multiselect 
                mode="tags"
                searchable
                valueProp="value"
                trackBy="label" label="label"
                :options="columnOptions" 
                :close-on-select="false" open-direction="bottom"
                v-model="data.columns" placeholder="--select--"></multiselect>
            </div>
        </div>
    </div>
    <div class="row mt-3 justify-content-end">
    <div class="col-12">
        <div class="mt-3 text-end">
            <b-button variant="primary" class="me-3"
            @click.prevent="filter()"
            type="button"> Export Columns </b-button>
        </div>
    </div>
    </div>
</form>
</template>
    
<script>
import Multiselect from '@vueform/multiselect'

export default {
    components: {
        Multiselect,
    },
    props:{
        columnOptions:{
            type: Array,
            default: () => ([])
        }
    },
    data() {
        return {
            data: {
                columns: []
            },
            mirrorData: null,
        }
    },
    methods: {
        filter() {
            this.$emit('addColumns', this.data.columns)
        },
    },
}
</script>
