<template>
    <form autocomplete="off" class="">
        <div class="row gy-3">
             <div class="col-12">
                <label class="">Status</label>
                <select class="form-select" v-model="data.status" name="status" placeholder="--select status--">
                    <option disabled value="" key="-1">-- select status--</option>
                    <option :value="status.value" v-for="(status, index) in statusOptions" :key="index">{{status.label}}</option>
                </select>
            </div>
            <div class="col-12">
                <div class="d-flex mt-3 justify-content-end">
                    <b-button variant="primary" 
                    @click.prevent="update()"
                     type="button">Update </b-button>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import { required, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

export default {
    setup() {
        return { v$: useVuelidate() };
    },
    props:{
        editItem:{
            type: Object,
            default: null
        },
    },
    data(){
        return{
            data: {
                status: "",
            },
            statusOptions:[
                { value: "pending", label: "Pending" },
                { value: "approved", label: "Approved" },
                { value: "rejected", label: "Rejected" },
                { value: "collected", label: "Collected" }
            ],
        }
    },
    validations: {
        data:{
            status: {required: required},          
        }
    },
    methods:{
        update(){
             this.v$.$validate().then(result =>{
                if (!result) return;
                const formData = new FormData()
                formData.append('data', JSON.stringify(this.data))
                this.$store.dispatch("changeLoaderValue", true)
                this.$http.post(`/applications/${this.editItem.id}/update`,formData)
                    .then((response) => {
                        this.$store.dispatch("changeLoaderValue", false)
                        if(response.data.success){
                            this.$emit('updatedItem', response.data.data)
                            this.resetForm()
                        }
                    })
            
            })
        },
        resetForm(){
            this.v$.$reset()
            this.$emit("closeMe")
        }
    },
    mounted(){
        this.data = this.$filters.patchUpdatables(this.data, this.editItem)
    }
}
</script>