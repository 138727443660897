<template>
    <form autocomplete="off" class="">
        <is-loading v-if="isLoading" :box="true" />
        <h5>{{this.selected.length}} application{{this.selected.length > 1 ? 's':'' }} selected for this invitation</h5>
        <div class="row gy-3">
            <div class="col-12">
                <label class="form-label">
                    Collection <span class="text-danger">*</span>
                </label>
                <select class="form-select" v-model="data.collection_id"
                name="collection_id" placeholder="--select collection--">
                    <option disabled value="">--select collection--</option>
                    <option v-for="collection in collections" :key="collection.id"
                        :value="collection.id">{{collection.friendly_id}} - {{collection.title}}</option>
                </select>
            </div>  
            <div class="col-12">
                <div class="d-flex mt-3 justify-content-end">
                    <b-button variant="primary" 
                    @click.prevent="sendInvitation()"
                     type="button">Send Invitation</b-button>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import IsLoading from '@/components/IsLoading.vue'

export default {
    components: {
        IsLoading,
    },
    props:{
        selected:{
            type: Array
        }
    },
    data(){
        return{
            isLoading: true,
            data: {
                collection_id: "",
            },
        }
    },
    computed:{
        collections(){
            return this.$store.state.collection.list
        }
    },
    methods:{
        sendInvitation() {
            if(!this.data.collection_id) { this.alertError("Select a collection"); return;}
            this.confirmAction({
                text: "Please confirm this action before you proceed",
            }).then((result) => {
                if (result.value) {
                    const formData = new FormData()
                    formData.append('data', JSON.stringify({
                        applications: this.selected, 
                        collection_id: this.data.collection_id
                    }))
                    this.$store.dispatch("changeLoaderValue",true)
                    this.$http.post('/invitations/create', formData)
                    .then(response => {
                        this.$store.dispatch("changeLoaderValue",false)
                        if(response.data.success){
                            this.$emit('closeMe');
                        }
                    })
                }
            });
        
        },
        getOptionData(){
            this.$store.dispatch('collection/fetchAllItems')
            .then(response => this.isLoading = false )
        },
    },
    mounted(){
        this.getOptionData()
    }
}
</script>