
<template>
    <div class="row gy-2">
      <div class="col-12">
        <h5 class="mb-3">Support Forms</h5>
        <div class="row mb-3 gy-2 justify-content-between">
          <div class="col-lg-12 d-flex">
            <div class="dropdown no-arrow me-2">
              <a class="btn btn-light bg-white dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                <i class="bx bx-menu"></i>
              </a>
              <ul class="dropdown-menu">
                <li @click.prevent="regenerateForms()">
                  <button class="dropdown-item" type="button">
                    Regenerate Forms
                  </button>
                </li>
              </ul>
            </div>
            <button @click.prevent="exportSelected()" type="button" class="btn btn-primary" >
              <i class="mdi mdi-file-export-outline align-bottom"></i> Export Forms </button>
          </div>
          <div class="col-md-6 col-lg-4">
            <div class="input-group w-auto">
              <b-form-select style="max-width:100px;" class="form-select" v-model="itemsPerPage" :options="pageOptions"></b-form-select>
              <select v-model="defaultStatus" class="form-select flex-grow-1"
                @change="fetchItems()">
                <option value="" disabled selected>-- sort by status --</option>
                <option :value="null" key="-2" > All status </option>
                <option v-for="(status, tIndex) in statusOptions"
                  :value="status.value" :key="tIndex" > {{status.label}} </option>
              </select>
            </div>
          </div>
          <div class="col-md-5">
            <div class="input-group mb-0">
              <input v-model="searchQuery" @input="updateSearchQuery" type="search" class="form-control" placeholder="Search here..." aria-label="Search here..." aria-describedby="button-search_TB">
              <button @click="fetchItems()" class="btn btn-primary" type="button" id="button-search_TB"><span class="ri ri-search-line"></span></button>
            </div>
          </div>
        </div>
        <div class="mb-3">
          <p class="mb-0">Showing {{paginationInfo}} results</p>
        </div>
        <div class="card">
          <div class="card-body p-3">
            <div class="table-responsive mb-0 loading-viewport">
              <is-loading v-if="isLoading" :box="true" />
              <b-table striped hover ref="selectableTable" :items="pageData.data"
                :fields="columns" responsive="sm"  align="middle">
                <template v-for="(column, colIndex) in columns.filter((i) => i.sortable)"
                  v-slot:[`head(${column.key})`] >
                    <div :key="colIndex" @click="setSortOrder(column)" class="cursor-pointer
                    d-flex align-items-center">
                      {{ column.label }} <span v-html="getSortingLabel(column.key)"></span>
                    </div>
                </template>
                <template v-slot:head(check)>
                  <div style="width:3.5rem;" class="form-check text-center">
                    <label class="form-check-label me-2" for="tableSRadioHead">
                      <input @change="selectAllRows($event)" type="checkbox"
                       class="form-check-input" id="tableSRadioHead" />
                    </label>
                  </div>
                </template>
                <template v-slot:cell(check)="data">
                  <div style="width:3.5rem;" class="form-check text-center me-auto">
                    <input v-model="selectedItems" type="checkbox" :value="data.item.id" class="form-check-input me-2"
                     :id="`tableSRadio${data.item.id}`" @click="handleCheckboxClick($event, data.item)" />
                    <label class="form-check-label" :for="`tableSRadio${data.item.id}`"></label>
                  </div>
                </template>
                <template v-slot:cell(title)="data">
                  <div style="min-width:10rem;" class="text-uppercase cursor-pointer">
                    {{ data.item.title }}
                  </div>
                </template>
                <template v-slot:cell(friendly_id)="data">
                  <div style="min-width:20rem;" class="cursor-pointer">
                    {{ data.item.friendly_id }}
                  </div>
                </template>
                <template v-slot:cell(status)="data">
                  <div @click="navigateTo(data.item.id)"  class="cursor-pointer">
                    <span v-html="statusBadge(data.item.status)"></span>
                  </div>
                </template>
                <template v-slot:cell(lga)="data">
                  <div style="min-width:10rem;" v-if="data.item.lga" class="cursor-pointer">
                    <span class="text-dark font-sm">{{ data.item.lga.name }}</span>
                  </div>
                </template>
              </b-table>
            </div>
          </div>
        </div>
        <div class="mb-4">
          <b-pagination v-model="currentPage" :limit="4" class="pagination-rounded"
          :total-rows="pageData.total" :per-page="itemsPerPage"></b-pagination>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  
  import _ from 'lodash';
  import IsLoading from '@/components/IsLoading.vue';
  
  export default {
    name: "case-list",
    components:{
      IsLoading
    },
    data() {
      return {
        isLoading: false,
        defaultStatus: null,
        searchQuery:"",
        pageOptions: [10, 20, 50, 70, 100, 500, 1000],
        columns: [
          {
            key: "check",
          },
          {
            key: "title",
            label: "Title",
            sortable: true
          },
          {
            key: "friendly_id",
            label: "UUID",
          },
          {
            key: "status",
            label: "Status",
          },
          {
            key: "batch",
            label: "Bactch",
            sortable: true
          },
          {
              key: "lga",
              label: "Local Government",
          },
        ],
        statusOptions:[
          { value: 'used', label: "Used" },
          { value: 'unused', label: "Unused" }
        ],
        sortBy: {
          sort_column: "title",
          sort_direction: "asc"
        },
        selectedItems:[],
        lastCheckedIndex: null,
        pageData:{
          data: []
        }
      }
    },
    computed: {
      currentPage: {
        get() {
          return this.pageData.current_page
        },
        set(val) {
          if(!this.pageData.current_page || this.pageData.current_page == val) return
          this.fetchItems(val)
        }
      },
      itemsPerPage: {
        get() {
          return +this.pageData.per_page || 70
        },
        set(val) {
          this.fetchItems(1, val)
        }
      },
      paginationInfo(){
        if(!this.pageData.total) return '0 - 0 of 0'
        return `${this.currentPage * this.itemsPerPage - (this.itemsPerPage - 1)} 
        - ${this.pageData.total - this.currentPage * this.itemsPerPage > 0 
        ? this.currentPage * this.itemsPerPage : this.pageData.total}
        of ${this.pageData.total}`
      },
      getSortingLabel(){
        return key => {
          let iconClass = 'text-muted ri-arrow-up-line';
          if(this.sortBy.sort_column != key) return `<span class='ms-2 ${iconClass}'></span>`;
          iconClass = this.sortBy.sort_direction == 'desc' ? 'ri-arrow-up-line' : 'ri-arrow-down-line';
          return `<span class='ms-2 ${iconClass}'></span>`
        }
      }
    },
    methods: {
      selectAllRows(e) {
        if(e.target.checked){
          this.selectedItems = this.pageData.data.map(i => i.id );
        }else{
          this.selectedItems = []
        }
      },
      updateSearchQuery: _.debounce(function(string) {
        this.fetchItems()
      }, 2000),
      fetchItems(page, per_page = null){
        let payload = {page: page || 1, per_page: per_page || this.itemsPerPage,
          ...this.sortBy
        }
        if(this.defaultStatus){ payload.status = this.defaultStatus }
        if(this.searchQuery){ payload.search = this.searchQuery }
        this.isLoading = true
        this.$http.get(`/forms?${new URLSearchParams(payload).toString()}`)
          .then((response) => {
            this.isLoading = false
            if(response.data.success){
              this.pageData = response.data.data
              this.initializeAll();
            }
          })
      },
      setSortOrder(column) {
        if (this.sortBy.sort_column === column.key) {
          this.sortBy.sort_direction = this.sortBy.sort_direction === 'desc' ? 'asc' : 'desc';
        } else {
          this.sortBy.sort_column = column.key;
          this.sortBy.sort_direction = 'desc';
        }
        this.fetchItems();
      },
      exportSelected() {
        if(!this.selectedItems.length){
          this.alertError("Select the form you'd like to export."); return;
        }
        const payload = {
          url: '/forms/export',
          data: {
            items: this.selectedItems.join(','),
            filename: 'support_forms_',
          }
        }
        this.$store.dispatch("changeLoaderValue", true)
        this.$http.get(`${payload.url}?${new URLSearchParams(payload.data).toString()}`)
          .then((response) => {
            this.$store.dispatch("changeLoaderValue", false)
            if(response.data.success){
              Swal.fire({
                title: "Click to download built Forms",
                icon: "success",
                html: `<a href="${this.absoluteUrl(response.data.data.zip_file_url)}"
                 class="btn btn-primary" target="_blank"><i class="mdi mdi-folder-zip-outline"></i> Download Forms</a>`,
                showConfirmButton: false,
              });
            }
          })
      },
      regenerateForms(){
        Swal.fire({
          title: "How many forms per Local Government?",
          icon: "question",
          input: "number",
          inputValue: 50,
          inputPlaceholder: "Number of forms",
          inputAttributes: {
            autocapitalize: "off",
          },
          showCancelButton: true,
          confirmButtonText: "Regenerate",
          showLoaderOnConfirm: true,
          preConfirm: async (value) => {
            try {
              const url = `/forms/regenerate?per_local_government=${value}`;
              const response = await this.$http.get(url);
              
              if(response.status != 200){
                throw new Error(response.data.message);
              }
              if(!response.data.success){
                throw new Error(response.data.message);
              }
              return response.data;
              
            } catch (error) {
              Swal.showValidationMessage(`
                Request failed: ${error}
              `);
            }
          },
          allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
          if (result.isConfirmed) {
            this.fetchItems();
            Swal.fire({
              title: `${result.value.message}`,
            });
          }
        });
      },
      handleCheckboxClick(event, item) {
        const currentIndex = this.pageData.data.findIndex(i => i.id === item.id);
        if (this.lastCheckedIndex !== null && event.shiftKey) {
          let start = Math.min(this.lastCheckedIndex, currentIndex);
          let end = Math.max(this.lastCheckedIndex, currentIndex);

          for (let i = start; i <= end; i++) {
            const itemId = this.pageData.data[i].id;
            if (!this.selectedItems.includes(itemId)) {
              this.selectedItems.push(itemId);
            }
          }
        } else {
          this.lastCheckedIndex = currentIndex;
        }
      },
      initializeAll(){
        this.selectedItems = [];
        this.lastCheckedIndex = null
      }
    },
    mounted() {
      this.fetchItems()
    },
  }
  </script>
  
  