<template>
  <div>
    <div class="d-grid">
        <button type="button"  @click="popupTakePicture =  true" class="btn mb-2 btn-outline-dark">
                <span class="mdi mdi-camera"></span> Take Picture </button>

        <input type="file" accept="image/*" ref="fileInput" style="display:none;" @change="handleFileChange">
        <button type="button" class="btn btn-outline-primary" @click="triggerFileInput">Upload Image</button>
    </div>
    <b-modal centered hide-footer header-class="pb-0 border-bottom-0"
      v-model="cropper.modal" body-class="p-3 p-md-4" id="modal-1" title="Edit Image"
      @hide="resetForm()">
      <div v-if="cropper.modal">
        <vue-image-cropper :imgSrc="cropper.imgSrc"
          @completed="uploadFile($event)" />
      </div>
    </b-modal>
    <b-modal hide-footer hide-header  v-model="popupTakePicture" centered
        no-close-on-esc no-close-on-backdrop class="web-cam-moal-backdrop" 
        @hide="popupTakePicture = false" body-class="bg-light">
        <vue-web-cam v-if="popupTakePicture"
            @acceptImage="acceptCameraImage($event)"
            @closeMe="popupTakePicture = false" />
    </b-modal>
  </div>
</template>

<script>
import VueImageCropper from '@/components/VueImageCropper.vue';
import VueWebCam from '@/components/VueWebCam.vue';

export default {
    components:{
        VueImageCropper,
        VueWebCam
    },
    props:{
        uploadUrl:{
            type: String,
            default: "/api/uploads"
        },
    },
    data(){
        return{
            cropper:{
                modal: false,
                imgSrc: "",
                image: ""
            },
            popupTakePicture: false
        }
    },
    methods:{
        uploadFile(file){
            let formData = new FormData();
            formData.append('image_file', file);
            this.$store.dispatch("changeLoaderValue", true)
            this.$http.post(this.absoluteUrl(this.uploadUrl), formData)
            .then((response) => {
                this.$store.dispatch("changeLoaderValue")
                if(response.data.success){
                    this.cropper.image = response.data.data
                    this.$emit('uploadSuccess', response.data.data)
                    this.resetForm()
                }
            })
        }, 
        acceptCameraImage(data){
            // this.cropper.imgSrc = data.url;
            // this.cropper.modal = true
            this.uploadFile(data.file)
        },
        triggerFileInput() {
            this.$refs.fileInput.click();
        },
        handleFileChange(event) {
            const file = event.target.files[0];
            if(file.size > 2097152){
                this.alertError('File size is more than 2MB'); return;
            }
            this.$filters.fileReader(file)
                .then(result => {
                    this.cropper.imgSrc = result.dataURL;
                    this.cropper.modal = true
                })
                .catch(error => {
                    this.alertError('Error reading file '+error);
                });
        },
        resetForm() {
            this.cropper = { modal: false,  imgSrc: "" }
            this.popupTakePicture = false
        },
    }
}
</script>

<style>

</style>