
<template>
  <div>
    <div class="card">
      <div class="card-header">
        <h5 class="card-title">{{ title }}</h5>
      </div>
      <div class="card-body py-0 px-lg-3">
        <div class="table-responsive mb-0 loading-viewport">
          <is-loading v-if="isLoading" :box="true" />
          <b-table striped hover ref="selectableTable" :items="pageData.data"
            :fields="columns" responsive="sm" align="middle">
            <template v-for="(column, colIndex) in columns.filter((i) => i.sortable)"
              v-slot:[`head(${column.key})`] >
                <div :key="colIndex" @click="setSortOrder(column)" class="cursor-pointer
                d-flex align-items-center">
                  {{ column.label }} <span v-html="getSortingLabel(column.key)"></span>
                </div>
            </template>
            <template v-slot:cell(staff_name)="data">
              <div style="min-width:10rem;" 
                 class="cursor-pointer d-flex">
                 <img @click="showProfileImg(data.item.staff_image)" class="avatar me-2 me-sm-3 rounded-circle"
                  :src="absoluteUrl(data.item.staff_image)" />
                <div @click="navigateTo(data.item)">
                    <h6 class="mb-0">{{ data.item.staff_name }}</h6>
                    <small>{{ data.item.staff_mobile }}</small>
                </div>
              </div>
            </template>
            <template v-slot:cell(lga_name)="data">
              <div style="min-width:5rem;" @click="navigateTo(data.item)" class="cursor-pointer">
                {{ data.item.lga_name }}
              </div>
            </template>
            <template v-slot:cell(application_count)="data">
              <div style="min-width:4rem;" @click="navigateTo(data.item)" class="cursor-pointer">
                <h6 class="mb-0">{{ data.item.application_count }}</h6>
              </div>
            </template>
          </b-table>
        </div>
        <div class="mb-4">
          <b-pagination v-model="currentPage" :limit="4" class="pagination-rounded"
          :total-rows="pageData.total" :per-page="itemsPerPage"></b-pagination>
        </div>
      </div>
    </div>

    <b-modal fullscreen hide-footer v-model="popupModalList" body-class="bg-light" title="Filtered By Staff"
      @hide="initializeAll()" header-class="pb-3">
      <div v-if="listItem" class="row mb-4">
        <div class="col-6">
          <div style="min-width:10rem;" class="cursor-pointer d-flex">
              <img @click="showProfileImg(listItem.staff_image)" class="avatar me-2 me-sm-3 rounded-circle"
              :src="absoluteUrl(listItem.staff_image)" />
            <router-link :to="`/users/${listItem.id}`">
              <h6 class="mb-0">{{ listItem.staff_name }}</h6>
              <small>{{ listItem.staff_mobile }}</small>
            </router-link>
          </div>
        </div>
        <div class="col-4">
          <div style="min-width:5rem;" class="">
            {{ listItem.lga_name }}
          </div>
        </div>
        <div class="col-2">
          <div style="min-width:4rem;" class="">
            <h6 class="mb-0">{{ listItem.application_count }}</h6>
          </div>
        </div>
      </div>
      <Applications v-if="popupModalList" :propFilters="listFilters"/>
    </b-modal>
  </div>
  </template>
  
<script>

import IsLoading from '@/components/IsLoading.vue';
import Applications from '@/views/applications/Applications.vue';
  
export default {
  name: "top-medicines",
  props:{
    hospitalId: {
      type: Number,
      default: null
    },
    title:{
      default: 'Applications Per Staff'
    }
  },
  components:{
    IsLoading,
    Applications
  },
  data() {
    return {
      isLoading: false,
      popupModalList: false,
      listFilters: null,
      listItem: null,
      columns: [
        {
          key: "staff_name",
          label: "Staff Name",
          sortable: true,
        },
        {
          key: "lga_name",
          label: "LGA",
          sortable: true,
        },
        {
          key: "application_count",
          label: "Count",
          sortable: true
        },
      ],
      sortBy: {
        sort_column: "application_count",
        sort_direction: "desc"
      },
      pageData:{
        data: []
      }
    }
  },
  computed: {
    currentPage: {
      get() {
        return this.pageData.current_page
      },
      set(val) {
        if(!this.pageData.current_page || this.pageData.current_page == val) return
        this.fetchItems(val)
      }
    },
    itemsPerPage: {
      get() {
        return +this.pageData.per_page || 8
      },
      set(val) {
        this.fetchItems(1, val)
      }
    },
    paginationInfo(){
      if(!this.pageData.total) return '0 - 0 of 0'
      return `${this.currentPage * this.itemsPerPage - (this.itemsPerPage - 1)} 
      - ${this.pageData.total - this.currentPage * this.itemsPerPage > 0 
      ? this.currentPage * this.itemsPerPage : this.pageData.total}
      of ${this.pageData.total}`
    },
    getSortingLabel(){
      return key => {
        let iconClass = 'text-muted ri-arrow-up-line';
        if(this.sortBy.sort_column != key) return `<span class='ms-2 ${iconClass}'></span>`;
        iconClass = this.sortBy.sort_direction == 'desc' ? 'ri-arrow-up-line' : 'ri-arrow-down-line';
        return `<span class='ms-2 ${iconClass}'></span>`
      }
    }
  },
  methods: {
    navigateTo(item){
      this.listFilters = {
        created_by: item.id,
      }
      this.listItem = {...item}
      this.popupModalList = true;
    },
    fetchItems(page, per_page = null){
      let payload = {page: page || 1, per_page: per_page || this.itemsPerPage,
        ...this.sortBy
      }
      this.isLoading = true
      this.$http.get(`/analytics/top-submissions?${new URLSearchParams(payload).toString()}`)
        .then((response) => {
          this.isLoading = false
          if(response.data.success){
            this.pageData = response.data.data
          }
        })
    },
    setSortOrder(column) {
      if (this.sortBy.sort_column === column.key) {
        this.sortBy.sort_direction = this.sortBy.sort_direction === 'desc' ? 'asc' : 'desc';
      } else {
        this.sortBy.sort_column = column.key;
        this.sortBy.sort_direction = 'desc';
      }
      this.fetchItems();
    },
    showProfileImg(image){
      const data = { index: 0, visible: true, files: [ this.absoluteUrl(image)] }
      this.$store.commit('TOGGLE_LIGHT_BOX', data)
    },
    initializeAll(){
      this.popupModalList = false;
      this.listFilters = null;
    }
  },
  mounted() {
    this.fetchItems()
  },
}

</script>
  
  