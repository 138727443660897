// axios
import axios from 'axios';
import router from "../router";
import env from '../env.js'
import store from "../store/store";

var isLoggedOut = false;

const axiosClient = axios.create({
  baseURL: `${env.domain}/api/admin/`,
  headers: {'X-Requested-With': 'XMLHttpRequest'}
});

axiosClient.interceptors.request.use((config) => {
  config.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
  config.headers.Authorization = `Bearer ${getoken()}`
  return config;
})

axiosClient.interceptors.response.use((response) => {
  alertMessage(response.data)
  return response;
}, (error) => {
  alertMessage(error.response.data)
  if (error.response.status === 401) {
    logout();
  }
  return error.response;
})

function getoken(){
  const localUser = localStorage.getItem(env.localUserKey);
  if(!localUser) return null;
  const storedData =  JSON.parse(unescape(atob(localUser)));
  return storedData.token;
}

function alertMessage(data){
  if(isLoggedOut) return;
  if(!data.message) return;
  if(Array.isArray(data.message)){
    data.message.forEach((msg) => {  data.success 
      ? store.dispatch("alertSuccess", msg) 
      : store.dispatch("alertError", msg)
    })
  }else{
    data.success 
      ? store.dispatch("alertSuccess", data.message) 
      : store.dispatch("alertError", data.message)
  }
}
function logout(){
  isLoggedOut = true;
  localStorage.removeItem(env.localUserKey)
  router.push({path: '/login', query:{ intended: location.pathname }})
}

export default axiosClient