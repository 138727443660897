
<template>
    <form autocomplete="off" class="">
        <is-loading v-if="isLoading" :box="true" />
        <div class="row g-lg-4 g-3">
            <div class="col-sm-6 col-lg-4">
                <label class="form-label">Created between</label>
                <flat-pickr
                    v-model="data.created_at"
                    :config="rangeConfig"
                    placeholder="Select date"
                    class="form-control flatpickr-input"
                ></flat-pickr>
            </div>
            <div class="col-sm-6 col-md-4">
                <div class="">
                <label>Local government </label>
                <multiselect 
                    mode="single"
                    searchable
                    valueProp="id"
                    trackBy="name" label="name"
                    :options="lgaOptions" 
                    v-model="data.local_government_id" placeholder="--select--"></multiselect>
                </div>
            </div>
            <div class="col-sm-6 col-md-4">
                <div class="">
                <label>Trade </label>
                <multiselect 
                mode="single"
                searchable
                valueProp="id"
                trackBy="title" label="title"
                :options="tradeOptions" 
                v-model="data.trade_id" placeholder="--select--"></multiselect>
                </div>
            </div> 
            <div class="col-md-6 col-lg-4">
                <label class="form-label"> Type of Business</label>
                <select v-model="data.business_type" class="form-select">
                    <option disabled value=""> --select type-- </option>
                    <option v-for="(businessType, yIndex) in businessTypes" :key="yIndex"
                    :value="businessType.value">{{businessType.label}}</option>
                </select>
            </div>
            <div class="col-md-6 col-lg-4">
                <label class="form-label"> Number of Employees</label>
                <select v-model="data.number_employees" class="form-select">
                    <option disabled value=""> --select-- </option>
                    <option v-for="(numEmployee, yIndex) in employeeOptions" :key="yIndex"
                    :value="numEmployee.value">{{numEmployee.label}}</option>
                </select>
            </div>
            <div class="col-md-6 col-lg-4">
                <label class="form-label"> Annual Revenue Range</label>
                <select v-model="data.annual_revenue" class="form-select">
                    <option disabled value=""> --select-- </option>
                    <option v-for="(revenueOption, yIndex) in revenueOptions" :key="yIndex"
                    :value="revenueOption.value">{{revenueOption.label}}</option>
                </select>
            </div> 
        </div>
        <div class="row mt-3 justify-content-end">
        <div class="col-12">
            <div class="mt-3 text-end">
            <b-button variant="primary" class="me-3"
                @click.prevent="getList()"
                type="button"> Get List </b-button>
    
            <b-button variant="dark" 
                @click.prevent="resetForm()"
                type="button"> Reset </b-button>
            </div>
        </div>
        </div>
    </form>
    </template>
        
    <script>
    import Multiselect from '@vueform/multiselect'
    import flatPickr from "vue-flatpickr-component";
    import IsLoading from '@/components/IsLoading.vue'
    
    export default {
        components: {
            IsLoading,
            Multiselect,
            flatPickr
        },
        data() {
        return {
            isLoading: true,
            data: {
                created_at: "",
                trade_id: "",
                local_government_id: "",
                business_type: "",
                number_employees: "",
                annual_revenue: "",
                market_reach: "",
            },
            dateConfig:{
                mode: "range",
                dateFormat: "Y-m-d",
                altInput: true,
                showMonths: 1,
                altFormat: "F j, Y",
            },
            rangeConfig: {
                mode: "range",
                dateFormat: "Y-m-d",
                altInput: true,
                showMonths: 2,
                altFormat: "F j, Y",
                // minDate: "today",
                maxDate: new Date().fp_incr(1)
            },
            businessTypes: [
                { value: "Retail", label: "Retail"},
                { value: "Manufacturing", label: "Manufacturing"},
                { value: "Service", label: "Service"},
                { value: "Food and Beverage", label: "Food and Beverage"},
                { value: null, label: "Others"}
            ],
            employeeOptions: [
                { value: "1-5", label: "1-5"},
                { value: "6-10", label: "6-10"},
                { value: "11-20", label: "11-20"},
                { value: "21-50", label: "21-50"},
                { value: "More than 50", label: "More than 50"}
            ],
            revenueOptions: [
                { value: "Less than N100,000", label: "Less than N100,000"},
                { value: "N100,001 – N500,000", label: "N100,001 – N500,000"},
                { value: "N500,001 – N1,000,000", label: "N500,001 – N1,000,000"},
                { value: "More than N1,000,000", label: "More than N1,000,000"},
            ],
            marketOptions: [
                { value: "Local", label: "Local"},
                { value: "Regional", label: "Regional"},
                { value: "National", label: "National"},
                { value: "International", label: "International"},
            ],
            mirrorData: null,
        }
        },
        computed:{
            lgaOptions(){
                return this.$store.state.localGovernments
            },
            tradeOptions(){
                return this.$store.state.trade.list
            },
        },
        methods: {
            getList() {
                var payload = Object.fromEntries(
                    Object.entries(this.data).filter(([_, v]) => !!v )
                );
                this.$emit('startSearch', payload)
            },
            resetForm() {
                this.data = {...this.mirrorData}
                this.$emit('resetFilters')
            },
            getOptionData(){
                this.$store.dispatch('localGovernments')
                this.$store.dispatch('trade/fetchAllItems')
                .then(response => this.isLoading = false )
            },
        },
        mounted(){
            this.getOptionData()
            this.mirrorData = JSON.parse(JSON.stringify(this.data))
        },
    }
    </script>
    