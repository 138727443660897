import env from '../env.js'
import { getUserData } from '@/helpers/utils'

const state = {
    isLoading: false,
    AppActiveUser: getUserData(),
    domain: env.domain,
    localUserKey: env.localUserKey,
    appName: env.appName,
    config:{
        shortDesc: "ABS Management System."
    },
    windowWidth: null,
    countries: [],
    localGovernments: [],
    dashboardData:{
      "users": {
        "total": 0,
        "staff": 0,
        "admin": 0
      },
      "forms": {
        "total": 0,
        "unused": 0,
        "used": 0
      },
      "applications": {
        "total": 0,
        "pending": 0,
        "rejected": 0,
        "collected": 0,
      },
      "collections": {
        "total" : 0
      }
    },
    lightbox:{
      index: null,
      visible: false,
      files: []
    },
}

export default state
