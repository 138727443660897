<template>
<div class="web-camera-container">
  <div class="d-flex mb-3 w-100 align-items-center justify-content-between">
    <h5 class="me-2">Capture Image</h5>
    <button type="button" @click="toggleCamera" class="btn-close" aria-label="Close"></button>
  </div>
  <div v-show="isCameraOpen && isLoading" class="camera-loading">
    <ul class="loader-circle">
      <li></li>
      <li></li>
      <li></li>
    </ul>
  </div>
  <div v-if="isCameraOpen" v-show="!isLoading" class="camera-box" :class="{ 'flash' : isShotPhoto }">
    
    <div class="camera-shutter" :class="{'flash' : isShotPhoto}"></div>
      
    <video v-show="!isPhotoTaken" ref="camera" style="width:100%"  :height="337.5" autoplay></video>
    
    <canvas v-show="isPhotoTaken" id="photoTaken" ref="canvas" :width="450"  :height="337.5"></canvas>
  </div>
  <div v-if="!isLoading">
    <div v-if="isCameraOpen && !isPhotoTaken" class="camera-shoot">
      <button type="button" class="button" @click="takePhoto">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="256" height="256" viewBox="0 0 256 256" xml:space="preserve">
        <g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)" >
          <path d="M 78.637 81.733 H 11.363 C 5.098 81.733 0 76.605 0 70.302 V 31.613 c 0 -6.303 5.098 -11.432 11.363 -11.432 h 4.552 c 0.554 0 1.098 -0.096 1.617 -0.286 c 1.105 -0.405 2.021 -1.206 2.579 -2.256 l 1.374 -2.591 c 2.218 -4.184 6.522 -6.782 11.234 -6.782 h 24.563 c 4.712 0 9.017 2.599 11.233 6.782 l 1.375 2.592 c 0.831 1.567 2.438 2.542 4.195 2.542 h 4.552 C 84.902 20.182 90 25.31 90 31.613 v 38.688 c 0 3.828 -1.892 7.384 -5.06 9.513 C 83.072 81.069 80.893 81.733 78.637 81.733 z M 11.363 26.182 C 8.406 26.182 6 28.618 6 31.613 v 38.688 c 0 2.995 2.406 5.432 5.363 5.432 h 67.273 c 1.06 0 2.082 -0.311 2.957 -0.899 C 83.101 73.822 84 72.128 84 70.302 V 31.613 c 0 -2.995 -2.406 -5.432 -5.363 -5.432 h -4.552 c -3.983 0 -7.623 -2.196 -9.496 -5.732 l -1.375 -2.591 c -1.175 -2.216 -3.447 -3.593 -5.933 -3.593 H 32.719 c -2.485 0 -4.758 1.376 -5.933 3.592 l -1.374 2.592 c -1.252 2.361 -3.318 4.165 -5.818 5.08 c -1.183 0.432 -2.42 0.651 -3.679 0.651 H 11.363 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
          <path d="M 45 71.412 c -12.075 0 -21.899 -9.894 -21.899 -22.054 c 0 -5.886 2.275 -11.421 6.406 -15.586 c 2.113 -2.13 4.586 -3.776 7.351 -4.892 c 2.591 -1.046 5.331 -1.576 8.143 -1.576 c 12.075 0 21.899 9.893 21.899 22.054 c 0 2.824 -0.524 5.578 -1.558 8.185 c -1.105 2.782 -2.736 5.272 -4.849 7.401 C 56.355 69.115 50.854 71.412 45 71.412 z M 45 33.305 c -2.039 0 -4.023 0.383 -5.897 1.14 c -2.005 0.81 -3.8 2.005 -5.336 3.554 c -3.009 3.033 -4.666 7.068 -4.666 11.36 c 0 8.852 7.132 16.054 15.899 16.054 c 4.242 0 8.231 -1.667 11.233 -4.693 c 1.539 -1.551 2.727 -3.364 3.531 -5.39 c 0.753 -1.897 1.135 -3.907 1.135 -5.971 C 60.899 40.506 53.767 33.305 45 33.305 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
          <ellipse cx="75.184" cy="33.831" rx="3.234" ry="3.261" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) "/>
        </g>
        </svg>
      </button>
    </div>
    <div v-if="isPhotoTaken && isCameraOpen" class="py-4 camera-download">
      <!-- <a id="downloadPhoto" download="my-photo.jpg"
      class="button btn btn-dark" role="button" @click="downloadImage">
        Download
      </a> -->
      <button class="btn btn-dark me-2" @click="takePhoto" type="button"> Re-take </button>
      <button class="btn btn-success" @click="acceptPhoto"  type="button"> Accept </button>
    </div>
  </div>
</div>
</template>

<script>
export default {
  data() {
    return {
      isCameraOpen: false,
      isPhotoTaken: false,
      isShotPhoto: false,
      isLoading: false,
      link: '#'
    }
  },
  methods: {
    toggleCamera() {
      if(this.isCameraOpen) {
        this.isCameraOpen = false;
        this.isPhotoTaken = false;
        this.isShotPhoto = false;
        this.stopCameraStream();

        this.$emit('closeMe')
      } else {
        this.isCameraOpen = true;
        this.createCameraElement();
      }
    },
    createCameraElement() {
      this.isLoading = true;
      
      const constraints = (window.constraints = {
				audio: false,
				// video: true
        video: {
          facingMode: { ideal: "environment" } // Request the rear camera
        }
			});


			navigator.mediaDevices
				.getUserMedia(constraints)
				.then(stream => {
          this.isLoading = false;
					this.$refs.camera.srcObject = stream;
				})
				.catch(error => {
          this.isLoading = false;
					alert("May the browser didn't support or there is some errors.");
          console.error(error);
				});
    },
    stopCameraStream() {
      let tracks = this.$refs.camera.srcObject.getTracks();

			tracks.forEach(track => {
				track.stop();
			});
    },
    
    takePhoto() {
      if(!this.isPhotoTaken) {
        this.isShotPhoto = true;

        const FLASH_TIMEOUT = 50;

        setTimeout(() => {
          this.isShotPhoto = false;
        }, FLASH_TIMEOUT);
      }
      
      this.isPhotoTaken = !this.isPhotoTaken;
      
      const context = this.$refs.canvas.getContext('2d');
      context.drawImage(this.$refs.camera, 0, 0, 450, 337.5);
    },
    downloadImage() {
      const download = document.getElementById("downloadPhoto");
      const canvas = document.getElementById("photoTaken").toDataURL("image/jpeg")
                    .replace("image/jpeg", "image/octet-stream");
      download.setAttribute("href", canvas);
    },
    async acceptPhoto() {
      const canvas = document.getElementById("photoTaken");
      const data = { file: null, url: null };

      data.file = await new Promise(resolve => {
          canvas.toBlob(blob => {
              resolve(new File([blob], "photo.jpg", { type: "image/jpeg" }));
          }, "image/jpeg");
      });

      data.url = canvas.toDataURL("image/jpeg");

      this.$emit('acceptImage', data);
      this.toggleCamera();
    }
  },
  mounted(){
    this.toggleCamera()
  },
}
</script>

<style lang="scss">
.web-camera-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // border: 1px solid #ccc;
  // border-radius: 4px;
  
  .camera-button {
    margin-bottom: 2rem;
  }
  
  .camera-box {    
    .camera-shutter {
      opacity: 0;
      width: 450;
      height: 337.5px;
      background-color: #fff;
      position: absolute;
      
      &.flash {
        opacity: 1;
      }
    }
  }
  
  .camera-shoot {
    margin: 1rem 0;
    
    button {
      height: 60px;
      width: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      
      svg {
        height: 35px;
        object-fit: cover;
      }
    }
  }
  
  .camera-loading {
    overflow: hidden;
    height: 100%;
    position: absolute;
    width: 100%;
    min-height: 150px;
    margin: 3rem 0 0 -1.2rem;
    
    ul {
      height: 100%;
      position: absolute;
      width: 100%;
      z-index: 999999;
      margin: 0;
    }
    
    .loader-circle {
      display: block;
      height: 14px;
      margin: 0 auto;
      top: 50%;
      left: 100%;
      transform: translateY(-50%);
      transform: translateX(-50%);
      position: absolute;
      width: 100%;
      padding: 0;
      
      li {
        display: block;
        float: left;
        width: 10px;
        height: 10px;
        line-height: 10px;
        padding: 0;
        position: relative;
        margin: 0 0 0 4px;
        background: #999;
        animation: preload 1s infinite;
        top: -50%;
        border-radius: 100%;
        
        &:nth-child(2) {
          animation-delay: .2s;
        }
        
        &:nth-child(3) {
          animation-delay: .4s;
        }
      }
    }
  }

  @keyframes preload {
    0% {
      opacity: 1
    }
    50% {
      opacity: .4
    }
    100% {
      opacity: 1
    }
  }
}
</style>