
<template>
  <div class="row gy-2">
    <div class="col-12">
      <h5 class="mb-3">Trades</h5>
      <div class="row mb-3 gy-2 justify-content-between">
        <div class="col-sm-12 col-md-4">
          <div class="input-group">
            <b-form-select style="max-width:100px;" class="form-select" v-model="itemsPerPage" :options="pageOptions"></b-form-select>
            <b-button variant="primary" class="" @click.prevent="popupModalShow = true" type="button">
            <i class="ri ri-add-fill align-bottom"></i> <span class="d-inline"> Create New </span></b-button>
          </div>
        </div>
        <div class="col-sm-12 col-md-6">
          <div class="input-group mb-0">
            <input v-model="searchQuery" @input="updateSearchQuery" type="search" class="form-control" placeholder="Search here..." aria-label="Search here..." aria-describedby="button-search_TB">
            <button @click="fetchItems()" class="btn btn-primary" type="button" id="button-search_TB"><span class="ri ri-search-line"></span></button>
          </div>
        </div>
      </div>
      <div class="mb-3">
        <p class="mb-0">Showing {{paginationInfo}} results</p>
      </div>
      <is-loading v-if="isLoading" />
      <div v-else>
        <div v-if="pageData.data.length" class="row">
          <div class="col-sm-6 col-lg-4" v-for="(item, dIndex) of pageData.data" :key="dIndex">
            <trade-card :item="item" >
              <template v-slot:actions>
                <div class="dropdown dropstart no-arrow">
                  <button class="dropdown-toggle btn btn-sm btn-light" type="button"
                    role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <i class="ri-more-2-fill"></i>
                  </button>
                  <ul class="dropdown-menu">
                    <li>
                      <button @click.prevent="initEdit(item)" class="dropdown-item"
                        type="button"> Edit </button>
                    </li>
                    <li>
                      <button @click.prevent="deleteItem(item.id)" class="dropdown-item"
                        type="button"> Delete </button>
                    </li>
                  </ul>
                </div>
              </template>
            </trade-card>
          </div>
        </div>
        <div v-else class="fs-5 py-5 text-muted text-center">There is no item added!</div>
      </div>
      <div class="mb-4">
        <b-pagination v-model="currentPage" :limit="3" class="pagination-rounded"
          :total-rows="pageData.total" :per-page="itemsPerPage"></b-pagination>
      </div>
      <b-modal v-model="popupModalShow" hide-footer centered
        :title="editMode? 'Update trade':'Add trade'"
        title-class="fs-6" @hide="initializeAll()">
        <trade-form v-if="popupModalShow" @closeMe="initializeAll()"
        :edit-mode="editMode" :edit-item="editItem" />
      </b-modal>
    </div>
  </div>
</template>

<script>
import IsLoading from '@/components/IsLoading.vue'
import _ from 'lodash';
import TradeCard from '@/components/cards/TradeCard.vue'
import TradeForm from "@/components/forms/TradeForm.vue"

export default {
  name: "trades",
  components:{
    IsLoading,
    TradeCard,
    TradeForm,
  },
  data() {
    return {
      isLoading: false,
      popupModalShow: false,
      editMode: false,
      editItem: null,
      searchQuery: "",
      pageOptions: [10, 25, 50, 100],
    }
  },
  watch: {
    defaultType (val) {
      this.fetchItems()
    }
  },
  computed: {
    pageData(){
      return this.$store.state.trade.pageData
    },
    currentPage: {
      get() {
        return this.pageData.current_page
      },
      set(val) {
        if(!this.pageData.current_page || this.pageData.current_page == val) return
        this.fetchItems(val)
      }
    },
    itemsPerPage: {
      get() {
        return +this.pageData.per_page || 25
      },
      set(val) {
        this.fetchItems(1, val)
      }
    },
    paginationInfo(){
      if(!this.pageData.total) return '0 - 0 of 0'
      return `${this.currentPage * this.itemsPerPage - (this.itemsPerPage - 1)} 
      - ${this.pageData.total - this.currentPage * this.itemsPerPage > 0 
      ? this.currentPage * this.itemsPerPage : this.pageData.total}
      of ${this.pageData.total}`
    },
  },
  methods: {
    updateSearchQuery: _.debounce(function(string) {
      this.fetchItems()
    }, 2000),
    fetchItems(page, per_page = null) {
      let payload = {page: page || 1, per_page: per_page || this.itemsPerPage }
      if(this.searchQuery){ payload.search = this.searchQuery }
      this.isLoading = true
      this.$store.dispatch("trade/fetchItems", payload)
      .then((_) => this.isLoading = false )
    },
    deleteItem(itemId) {
      this.confirmDelete({
        text: "You are about to delete a trade",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("changeLoaderValue",true)
          this.$http.delete(`/trades/${itemId}/delete`)
          .then(response => {
            this.$store.dispatch("changeLoaderValue",false)
            if(response.data.success){
              this.$store.commit('trade/DELETE_ITEM', itemId)
            }
          })
        }
      });
    },
    initEdit(item){
      this.editMode = true;
      this.editItem = {...item}
      this.popupModalShow = true  
    },
    initializeAll(){
      this.editMode = false;
      this.editItem = null;
      this.popupModalShow = false;
    },
  },
  created(){
    this.fetchItems()
  },
}

</script>

