<template>
<div class="card card-height-100">
    <div class="card-body">
        <div class="d-flex mb-2 align-items-center justify-content-between">
            <div class="me-3">
                <h5 class="mb-0 card-title">{{item.title}}</h5>
            </div>
            <slot name="actions"></slot>
        </div>
        <p v-if="item.description" class="card-text mt-2 mb-0 small">{{item.description}}</p>        
    </div>
</div>
</template>

<script>
export default {
    props:{
        item:{
            type: Object,
            required: true
        }
    },      
}
</script>

<style>

</style>