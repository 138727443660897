<template>
    <form autocomplete="off" class="">
        <is-loading v-if="isLoading" :box="true" />
        <div class="row gy-3 mb-4">
            <div class="col-sm-10 col-md-6 align-self-end">
                <label class="form-label d-block" for="ProfileImage">Profile photo</label>
                <profile-image-uploader @uploadSuccess="updateImage($event)"/>
            </div>
        </div>
        <div class="row gy-3">
            <div class="col-md-6 col-lg-4">
                <label class="form-label" for="firstName">First name</label>
                <input id="firstName" type="text" class="form-control" v-model="data.first_name"
                :class="{ 'is-invalid': v$.data.first_name.$error }" placeholder="First name" name="first_name" />
                <div v-for="(item, index) in v$.data.first_name.$errors"
                    :key="index" class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div>
            <div class="col-md-6 col-lg-4">
                <label class="form-label" for="middleName">Middle name</label>
                <input id="middleName" type="text" class="form-control" v-model="data.middle_name"
                placeholder="Middle name" name="middle_name" />
            </div>
            <div class="col-md-6 col-lg-4">
                <label class="form-label" for="LastName">Last name</label>
                <input id="LastName" type="text" class="form-control" v-model="data.last_name"
                :class="{ 'is-invalid': v$.data.last_name.$error }"  placeholder="Last Name" name="last_name" />
                <div v-for="(item, index) in v$.data.last_name.$errors"
                    :key="index" class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div>
            <div class="col-md-6">
                <label class="form-label" for="inputEmail">Email Address</label>
                <input id="inputEmail" label="Email" v-model="data.email"
                :class="{ 'is-invalid': v$.data.email.$error }" type="email" placeholder="Enter email" name="email" class="form-control"/>
                <div v-for="(item, index) in v$.data.email.$errors"
                    :key="index" class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>  
            </div>
            <div class="col-md-6">
                <label class="form-label" for="inputMobile">Mobile</label>
                <input id="inputMobile" autocomplete="false" type="text" class="form-control" v-model="data.mobile"
                    :class="{ 'is-invalid': v$.data.mobile.$error  }" placeholder="mobile" name="mobile" />
                <div v-for="(item, index) in v$.data.mobile.$errors"
                    :key="index" class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>  
            </div>
            <div class="col-md-6">
                <label class="form-label">Role</label>
                <select class="form-select" v-model="data.role_id" 
                :class="{ 'is-invalid': v$.data.role_id.$error  }"
                    name="input-role" placeholder="--select role--">
                    <option disabled value=""> -- select role--</option>
                    <option :value="role.id" v-for="(role, index) in roleOptions" 
                    :key="index">{{$filters.capitalize(role.name)}}</option>
                </select>
                <div v-for="(item, index) in v$.data.role_id.$errors"
                    :key="index" class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div>
            <div class="col-md-6">
                <label for="gender" class="form-label">Gender</label>
                <select v-model="data.gender" name="gender" id="gender" class="form-select"
                 >
                <option disabled :value="null" >--select gender--</option>
                <option v-for="(gender, gdIndex) in genderOptions" :key="gdIndex" 
                    :value="gender.value">{{gender.label}}</option>
                </select>
            </div>
            <div class="col-md-6">
                <label class="form-label">Status</label>
                <select class="form-select" v-model="data.status"
                    name="input-role" placeholder="--select status--">
                    <option disabled :value="null" key="-1">-- select status--</option>
                    <option :value="status.value" v-for="(status, index) in statusOptions" 
                    :key="index">{{status.label}}</option>
                </select>
            </div>
            <div class="col-md-6 align-self-end">
                <div class="form-group">
                <label class="d-block">Verification</label>
                    <b-form-checkbox v-model="data.is_verified" name="is-verified" switch>
                    {{data.is_verified? 'Verified' : 'Not Verified'}}
                    </b-form-checkbox>
                </div>
            </div>
            <div class="col-sm-12">
                <label for="address" class="form-label"> User Address </label>
                <input v-model="data.address" id="address" name="address"
                class="form-control" type="text" placeholder="Address">
            </div>
            <div class="col-md-6">
                <label for="local_government_id" class="form-label">Local government</label>
                <select @change="lgaChange()" v-model="data.local_government_id" 
                    name="local_government_id" id="local_government_id" class="form-select">
                <option disabled value="" >--select LGA--</option>
                <option v-for="(lga, coIndex) in lgaOptions" :key="coIndex" 
                :value="lga.id">{{lga.name}}</option>
                </select>
            </div>
            <div class="col-md-6">
                <label> Ward </label>
                <multiselect 
                mode="single"
                searchable
                valueProp="id"
                trackBy="name" label="name"
                :options="wardOptions" 
                v-model="data.registration_area_id" placeholder="--select ward --"></multiselect>
            </div>
            <div class="col-12">
                <div class="d-flex mt-3 justify-content-end">
                    <b-button variant="primary" 
                    @click.prevent="update()"
                     type="button"> Update </b-button>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import IsLoading from '@/components/IsLoading.vue'
import { required, email, minLength, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import ProfileImageUploader from '@/components/forms/ProfileImageUploader.vue';
import Multiselect from '@vueform/multiselect'

export default {
    setup() {
        return { v$: useVuelidate() };
    },
    components: {
        IsLoading,
        ProfileImageUploader,
        Multiselect
    },
    props:{
        editItem:{
            type: Object,
            default: null
        }
    },
    data(){
        return{
            isLoading: true,
            data: {
                first_name: "",
                middle_name: "",
                last_name: "",
                email: "",
                mobile: null,
                role_id: "",
                gender: null,
                address: "",
                local_government_id: "",
                registration_area_id: "",
                assigned_lga_id: "",
                status: "",
                image: "",
                is_verified: false,
            },
            statusOptions: [
                {label: "Active", value:'active'},
                {label: "Retired", value:'retired'},
                {label: "Deactivated", value:'deactivated'},
                {label: "Blocked", value:'blocked'}
            ],
            genderOptions: [
                { label: 'Male', value: 'male' },
                { label: 'Female', value: 'female' },
                { label: 'Others', value: 'others' }
            ],
            wardOptions: []
        }
    },
    validations() {
        return {        
            data:{
                first_name: {
                    required: helpers.withMessage("First name is required", required),
                },
                last_name: {
                    required: helpers.withMessage("Last name is required", required),
                },
                email: {
                    required: helpers.withMessage("Email is required", required),
                    email: helpers.withMessage("Please enter valid email", email),
                },
                mobile: {
                    required: helpers.withMessage("Phone number is required", required),
                },
                image: {
                    required: helpers.withMessage("Profile photo is required", required),
                },
                role_id: {
                    required: helpers.withMessage("User role is required", required),
                },        
            }
        }
    },
    computed:{
        roleOptions() {
            return this.$store.state.userList.roles
        },
        lgaOptions(){
            return this.$store.state.localGovernments
        },
    },
    methods:{
        update(){
            this.v$.$validate().then(result =>{
                if (!result) { this.alertError(this.v$.$errors[0].$message); return };
                const formData = new FormData()
                formData.append('data', JSON.stringify(this.data))
                this.$store.dispatch("changeLoaderValue", true)
                this.$http.post(`/users/${this.editItem.id}/update`,formData)
                    .then((response) => {
                        this.$store.dispatch("changeLoaderValue", false)
                        if(response.data.success){
                            this.$store.commit("userList/UPDATE_USER", response.data.data)
                            this.resetForm()
                        }
                    })
            
            })
        },
        updateImage(image){
            this.data.image = image
            this.update()
        }, 
        lgaChange(initialize = true){
            if(initialize){
                this.data.registration_area_id = "";
            }
            this.wardOptions = [];
            if(!this.data.local_government_id){ return }
            this.isLoading = true;
            this.$http.get(`${this.absoluteUrl()}/api/registration-areas?local_government_id=${this.data.local_government_id}`)
                .then((response) => {
                this.isLoading = false
                if(response.data.success){
                    this.wardOptions = response.data.data
                }
            })
        },
        resetForm(){
            this.$emit("closeMe")
        },
        getOptionData(){
            this.isLoading = true;
            this.$store.dispatch("userList/fetchRoles")
            this.$store.dispatch('localGovernments')
            .then(response => this.isLoading = false );
        },
    },
    mounted(){
        this.getOptionData()
        this.data = this.$filters.patchUpdatables(this.data, this.editItem)
        this.lgaChange(false)
    }
}
</script>