<template>
    <form autocomplete="off" class="">
        <is-loading v-if="isLoading" :box="true" />
        <div class="row gy-3">
            <div class="col-md-6 col-lg-4">
                <label for="local_government_id" class="form-label">Local Government Area <span class="text-danger">*</span></label>
                <select @change="lgaChange()" v-model="data.local_government_id" name="local_government_id" id="local_government_id" class="form-select"
                    :class="{ 'is-invalid': v$.data.local_government_id.$error }">
                    <option disabled value="" >--choose LGA--</option>
                    <option v-for="(lga, lgIndex) in lgaOptions" :key="lgIndex" 
                    :value="lga.id">{{lga.name}}</option>
                </select>
                <div v-for="(item, index) in v$.data.local_government_id.$errors"
                    :key="index" class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div> 
            </div>
            <div class="col-md-6 col-lg-4">
                <label for="registration_area_id" class="form-label">Ward <span class="text-danger">*</span></label>
                <select @change="wardChange()" v-model="data.registration_area_id" name="registration_area_id" id="registration_area_id" class="form-select"
                    :class="{ 'is-invalid': v$.data.registration_area_id.$error }">
                    <option disabled value="" >--choose ward--</option>
                    <option v-for="(ward, wIndex) in wardOptions" :key="wIndex" 
                    :value="ward.id">{{ward.name}}</option>
                </select>
                <div v-for="(item, index) in v$.data.registration_area_id.$errors"
                    :key="index" class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div> 
            </div>
            <div class="col-lg-4">
                <div class="">
                    <label>Polling Unit </label>
                    <multiselect 
                        mode="single"
                        searchable
                        valueProp="id"
                        trackBy="name" label="name"
                        :options="unitOptions" 
                        v-model="data.polling_unit_id" placeholder="--select--"></multiselect>
                </div>
                <div v-for="(item, index) in v$.data.pu_code.$errors"
                    :key="index" class="text-danger">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div>
            <div class="col-md-6 col-lg-4">
                <label class="form-label" for="puCode">Polling Unit Code <span class="text-danger">*</span></label>
                <input id="puCode" type="text" class="form-control" v-model="data.pu_code"
                :class="{ 'is-invalid': v$.data.pu_code.$error }" placeholder="00-00-00-000" name="pu_code" />
                <div v-for="(item, index) in v$.data.pu_code.$errors"
                    :key="index" class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div>
            <div class="col-md-6 col-lg-8">
                <label class="form-label" for="vin_number">Voter Identification Number (VIN) <span class="text-danger">*</span></label>
                <input v-model="data.vin" id="vin_number" class="form-control" type="text"
                placeholder="VIN" name="vin" :class="{ 'is-invalid': v$.data.vin.$error }" >
                <div v-for="(item, index) in v$.data.vin.$errors"
                    :key="index" class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div> 
                
            </div>
            <div class="col-md-6">
                <label class="form-label" for="firstName">First Name <span class="text-danger">*</span></label>
                <input id="firstName" type="text" class="text-uppercase form-control" v-model="data.first_name"
                :class="{ 'is-invalid': v$.data.first_name.$error }" placeholder="First name" name="first_name" />
                <div v-for="(item, index) in v$.data.first_name.$errors"
                    :key="index" class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div>
            <div class="col-md-6">
                <label class="form-label" for="LastName">Last Name <span class="text-danger">*</span></label>
                <input id="LastName" type="text" class="text-uppercase form-control" v-model="data.last_name"
                :class="{ 'is-invalid': v$.data.last_name.$error }"  placeholder="Last Name" name="last_name" />
                <div v-for="(item, index) in v$.data.last_name.$errors"
                    :key="index" class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div>
            <div class="col-md-6 col-lg-4">
                <label for="gender" class="form-label">Gender <span class="text-danger">*</span></label>
                <select v-model="data.gender" name="gender" id="gender" class="form-select"
                :class="{ 'is-invalid': v$.data.gender.$error }" >
                <option disabled value="" >--select gender--</option>
                <option v-for="(gender, gdIndex) in genderOptions" :key="gdIndex" 
                    :value="gender.value">{{gender.label}}</option>
                </select>
                <div v-for="(item, index) in v$.data.gender.$errors"
                    :key="index" class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div> 
            </div>
            <div class="col-md-6 col-lg-4">
                <label class="form-label">
                    Year of birth <span class="text-danger">*</span>
                </label>
                <select v-model="data.yob" name="year"
                    :class="{ 'is-invalid': v$.data.yob.$error  }" class="form-select">
                    <option disabled value=""> --select Year-- </option>
                    <option v-for="(year, yIndex) in years" :key="yIndex"
                    :value="year.value">{{year.label}}</option>
                </select>
                <div v-for="(item, index) in v$.data.yob.$errors"
                    :key="index" class="text-danger">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div> 
            </div>
            <div class="col-md-6 col-lg-4">
                <label class="form-label"> Occupation <span class="text-danger">*</span></label>
                <select v-model="data.occupation" name="occupation"
                    :class="{ 'is-invalid': v$.data.occupation.$error  }" class="form-select">
                    <option disabled value=""> --select-- </option>
                    <option v-for="(occOption, yIndex) in occupationOptions" :key="yIndex"
                    :value="occOption.value">{{occOption.label}}</option>
                </select>
            </div>
            <div class="col-12">
                <div class="d-flex mt-3 justify-content-end">
                    <b-button variant="primary" 
                    @click.prevent="editMode? update() : create()"
                     type="button">{{editMode? 'Update':'Submit'}}</b-button>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import { required, minLength, requiredIf, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import IsLoading from '@/components/IsLoading.vue'
import Multiselect from '@vueform/multiselect'

export default {
    setup() {
        return { v$: useVuelidate() };
    },
    components:{
        IsLoading,
        Multiselect,
    },
    props:{
        editItem:{
            type: Object,
            default: null
        },
        editMode:{
            type: Boolean,
            default: false
        }
    },
    data(){
        return{
            isLoading: true,
            data: {
                local_government_id: "",
                registration_area_id: "",
                polling_unit_id:"",
                pu_code: "",
                vin: "",
                first_name: "",
                last_name: "",
                yob: "",
                gender: "",
                occupation: ""
            },
            genderOptions: [
                { label: 'Male', value: 'Male' },
                { label: 'Female', value: 'Female' },
                { label: 'Others', value: 'Others' }
            ],
            occupationOptions: [
               { value: "STUDENT", label: "STUDENT"},
               { value: "BUSINESS", label: "BUSINESS"},
               { value: "ARTISAN", label: "ARTISAN"},
               { value: "OTHER", label: "OTHER"},
            ],
            wardOptions: [],
            unitOptions: [],
        }
    },
    validations() {
        return {        
            data:{
                local_government_id: {
                    required: helpers.withMessage("Beneficiary local government is required", required),
                },
                registration_area_id: {
                    required: helpers.withMessage("Beneficiary ward is required", required),
                },
                polling_unit_id: {
                    required: helpers.withMessage("Beneficiary polling unit is required", required),
                },
                pu_code: {
                    required: helpers.withMessage("Polling Unit Code is required", required),
                },
                vin: {
                    required: helpers.withMessage("VIN is required", required),
                },
                first_name: {
                    required: helpers.withMessage("First name is required", required),
                },
                last_name: {
                    required: helpers.withMessage("Last name is required", required),
                },
                gender: {
                    required: helpers.withMessage("Select gender", required),
                },   
                yob: {
                    required: helpers.withMessage("Year of birth is required", required),
                },  
                occupation: {
                    required: helpers.withMessage("Occupation is required", required),
                },                   
            }
        }
    },
    computed:{
        lgaOptions(){
            return this.$store.state.localGovernments
        },
        years() {
            let factor = 0;
            let startYear = new Date().getFullYear() - factor;
            return Array.from({ length: 100 }, (_, index) => {
                const yearValue = startYear - index;
                return {
                    value: yearValue,
                    label: yearValue.toString()
                };
            });
        },
    },
    methods:{
        create() {
            this.v$.$validate().then(result =>{
                if (!result) { this.alertError(this.v$.$errors[0].$message); return };
                let formData = new FormData();
                formData.append('data', JSON.stringify(this.data))
                this.$store.dispatch("changeLoaderValue", true)
                this.$http.post('/voter-identities/create', formData)
                .then((response) => {
                    this.$store.dispatch("changeLoaderValue", false)
                    if(response.data.success){
                        this.$emit('addItem', response.data.data)
                        this.resetForm();
                    }
                })
            })
        },
        update(){
            this.v$.$validate().then(result =>{
                if (!result) { this.alertError(this.v$.$errors[0].$message); return };
                const formData = new FormData()
                formData.append('data', JSON.stringify(this.data))
                this.$store.dispatch("changeLoaderValue", true)
                this.$http.post(`/voter-identities/${this.editItem.id}/update`,formData)
                    .then((response) => {
                        this.$store.dispatch("changeLoaderValue", false)
                        if(response.data.success){
                            this.$emit('updateItem', response.data.data)
                            this.resetForm()
                        }
                    })
            
            })
        },
        resetForm(){
            this.v$.$reset()
            this.wardOptions = [];
            this.unitOptions = [];
            this.$emit("closeMe")
        },
        lgaChange(clear = true){
            if(clear) { this.data.registration_area_id = this.data.polling_unit_id  = ""; }
            this.wardOptions = this.unitOptions = [];
            this.isLoading = true
            this.$http.get(`${this.absoluteUrl()}/api/registration-areas?local_government_id=${this.data.local_government_id}`)
                .then((response) => {
                this.isLoading = false
                if(response.data.success){
                    this.wardOptions = response.data.data
                }
            })
        },
        wardChange(clear = true){
            if(clear) { this.data.polling_unit_id = ""; }
            this.unitOptions = [];
            if(!this.data.local_government_id || !this.data.registration_area_id){
                return
            }
            this.isLoading = true
            this.$http.get(`${this.absoluteUrl()}/api/polling-units?local_government_id=${this.data.local_government_id}&registration_area_id=${this.data.registration_area_id}`)
                .then((response) => {
                this.isLoading = false
                if(response.data.success){
                    this.unitOptions = response.data.data
                }
            })
        },
        getOptionData(){
            this.isLoading = true;
            this.$store.dispatch('localGovernments')
            .then(response => this.isLoading = false );
        },
    },
    mounted(){
        this.getOptionData()
        if(this.editMode){
            this.data = this.$filters.patchUpdatables(this.data, this.editItem)
            if(this.editItem.unit){  this.data.registration_area_id = this.editItem.unit.registration_area_id }
            if(this.editItem.ward){  this.data.local_government_id = this.editItem.ward.local_government_id }
            this.lgaChange(false);
            this.wardChange(false)
        }
    }
}
</script>