<template>
    <form autocomplete="off" class="loading-viewport">
        <is-loading v-if="isLoading" :box="true" />
        <div class="row gx-lg-4 g-3">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="mb-3">
                            <h5 class="mb-2">USER DETAILS</h5>
                            <p class="mb-0">Complete the User information below:</p>
                        </div>
                        <div class="row gy-3 mb-4">
                            <div class="col-sm-3 col-lg-2">
                                <label class="form-label d-block" for="ProfileImage">Profile photo</label>
                                <img v-if="data.image" width="100" class="" :src="absoluteUrl(data.image)" alt="profile logo" />
                                <svg v-else xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="100" height="100" viewBox="0 0 256 256" xml:space="preserve">
                                    <g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)" >
                                        <path d="M 90 90 H 77.615 c -21.744 -5.296 -43.487 -5.296 -65.231 0 H 0 V 0 h 90 V 90 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(200,209,230); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                                        <path d="M 67.319 68.576 l -4.863 -2.716 c -3.576 -1.997 -6.386 -4.994 -8.161 -8.53 c 2.468 -3.578 4.072 -8.116 4.271 -11.885 h 0.022 l 1.346 -6.015 c 0.298 -1.332 -0.306 -2.604 -1.346 -3.293 v -2.179 c 0 -6.227 -4.193 -13.335 -9.906 -15.582 l 0.009 -0.015 c -2.81 -1.268 -6.633 -1.184 -8.423 -0.718 c 1.849 0.664 2.835 2.557 0.425 3.426 l 0.022 0.001 c -5.402 1.796 -9.302 6.882 -9.302 12.887 v 2.179 c -1.041 0.689 -1.644 1.96 -1.346 3.293 l 1.346 6.015 h 0.022 c 0.199 3.769 1.802 8.307 4.271 11.885 c -1.775 3.536 -4.586 6.533 -8.161 8.53 l -4.863 2.716 c -6.357 3.55 -10.296 10.262 -10.296 17.543 V 90 h 65.231 v -3.881 C 77.615 78.838 73.676 72.126 67.319 68.576 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(247,247,247); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                                    </g>
                                </svg>
                            </div>
                            <div class="col-md-5 col-lg-3 align-self-end">
                                <profile-image-capture @uploadSuccess="($event) => { data.image = $event }"/>
                            </div>
                        </div>
                        <div class="row gy-3">
                            <div class="col-md-6 col-lg-4">
                                <label class="form-label" for="firstName">First name</label>
                                <input id="firstName" type="text" class="form-control" v-model="data.first_name"
                                :class="{ 'is-invalid': v$.data.first_name.$error }" placeholder="First name" name="first_name" />
                                <div v-for="(item, index) in v$.data.first_name.$errors"
                                    :key="index" class="invalid-feedback">
                                    <span v-if="item.$message">{{ item.$message }}</span>
                                </div>
                            </div>
                            <div class="col-md-6 col-lg-4">
                                <label class="form-label" for="middleName">Middle name</label>
                                <input id="middleName" type="text" class="form-control" v-model="data.middle_name"
                                placeholder="Middle name" name="middle_name" />
                            </div>
                            <div class="col-md-6 col-lg-4">
                                <label class="form-label" for="LastName">Last name</label>
                                <input id="LastName" type="text" class="form-control" v-model="data.last_name"
                                :class="{ 'is-invalid': v$.data.last_name.$error }"  placeholder="Last Name" name="last_name" />
                                <div v-for="(item, index) in v$.data.last_name.$errors"
                                    :key="index" class="invalid-feedback">
                                    <span v-if="item.$message">{{ item.$message }}</span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <label class="form-label" for="inputEmail">Email Address</label>
                                <input id="inputEmail" label="Email" v-model="data.email"
                                :class="{ 'is-invalid': v$.data.email.$error }" type="email" placeholder="Enter email" name="email" class="form-control"/>
                                <div v-for="(item, index) in v$.data.email.$errors"
                                    :key="index" class="invalid-feedback">
                                    <span v-if="item.$message">{{ item.$message }}</span>
                                </div>  
                            </div>
                            <div class="col-md-6">
                                <label class="form-label" for="inputMobile">Mobile</label>
                                <input id="inputMobile" autocomplete="false" type="text" class="form-control" v-model="data.mobile"
                                    :class="{ 'is-invalid': v$.data.mobile.$error  }" placeholder="mobile" name="mobile" />
                                <div v-for="(item, index) in v$.data.mobile.$errors"
                                    :key="index" class="invalid-feedback">
                                    <span v-if="item.$message">{{ item.$message }}</span>
                                </div>  
                            </div>
                            <div class="col-md-6 col-lg-4">
                                <label class="form-label">Role</label>
                                <select class="form-select" v-model="data.role_id" 
                                :class="{ 'is-invalid': v$.data.role_id.$error  }"
                                    name="input-role" placeholder="--select role--">
                                    <option disabled value=""> -- select role--</option>
                                    <option :value="role.id" v-for="(role, index) in roleOptions" 
                                    :key="index">{{$filters.capitalize(role.name)}}</option>
                                </select>
                                <div v-for="(item, index) in v$.data.role_id.$errors"
                                    :key="index" class="invalid-feedback">
                                    <span v-if="item.$message">{{ item.$message }}</span>
                                </div>
                            </div>
                            <div class="col-md-6 col-lg-4">
                                <label for="gender" class="form-label">Gender</label>
                                <select v-model="data.gender" name="gender" id="gender" class="form-select">
                                <option disabled :value="null" >--select gender--</option>
                                <option v-for="(gender, gdIndex) in genderOptions" :key="gdIndex" 
                                    :value="gender.value">{{gender.label}}</option>
                                </select>
                            </div>
                            <div class="col-md-6 col-lg-4">
                                <label class="form-label" for="password">Password</label>
                                <input type="password" v-model="data.password" class="form-control"
                                name="password" id="password" placeholder="Enter password" :class="{ 'is-invalid': v$.data.password.$error }">
                                <div v-for="(item, index) in v$.data.password.$errors" :key="index"
                                    class="invalid-feedback">
                                    <span v-if="item.$message">{{ item.$message }}</span>
                                </div>
                            </div>
                            <div class="col-md-6 align-self-end">
                                <div class="form-group">
                                <label class="d-block">Verification</label>
                                    <b-form-checkbox v-model="data.is_verified" name="is-verified" switch>
                                    {{data.is_verified? 'Verified' : 'Not Verified'}}
                                    </b-form-checkbox>
                                </div>
                            </div>
                            <div class="col-sm-12">
                                <label for="address" class="form-label"> User Address </label>
                                <input v-model="data.address" id="address" name="address"
                                class="form-control" type="text" placeholder="Address">
                            </div>
                            <div class="col-md-6">
                                <label for="local_government_id" class="form-label">Local government</label>
                                <select @change="lgaChange()" v-model="data.local_government_id" 
                                    name="local_government_id" id="local_government_id" class="form-select">
                                <option disabled value="" >--select LGA--</option>
                                <option v-for="(lga, coIndex) in lgaOptions" :key="coIndex" 
                                :value="lga.id">{{lga.name}}</option>
                                </select>
                            </div>
                            <div class="col-md-6">
                                <label> Ward </label>
                                <multiselect 
                                mode="single"
                                searchable
                                valueProp="id"
                                trackBy="name" label="name"
                                :options="wardOptions" 
                                v-model="data.registration_area_id" placeholder="--select ward --"></multiselect>
                            </div>
                        </div> 
                    </div>
                </div>
                <div v-if="enableLgaAssignment" class="card">
                    <div class="card-body">
                        <div class="mb-3">
                            <h5 class="mb-2">Assigned Local Government Area</h5>
                            <p class="mb-0">Select the Local Government Area the Staff will carry out the registration</p>
                        </div>
                        <div class="row gx-lg-4 g-3">
                            <div class="col-slg-6">
                                <label>Assigned Local Government</label>
                                <multiselect 
                                    mode="single"
                                    searchable
                                    valueProp="id"
                                    trackBy="name" label="name"
                                    :options="lgaOptions" 
                                    open-direction="bottom"
                                    v-model="data.assigned_lga_id" placeholder="--select LGA--">
                                </multiselect>

                                <div v-for="(item, index) in v$.data.assigned_lga_id.$errors"
                                    :key="index" class="text-danger">
                                    <span v-if="item.$message">{{ item.$message }}</span>
                                </div>
                            </div> 
                        </div> 
                    </div>
                </div>
            </div>
        </div>
        <div class="row justify-content-end">
            <div class="col-12">
                <div class="mt-3">
                    <b-button size="lg" variant="primary" 
                    @click.prevent="create()"
                    type="button"> Submit </b-button>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import { required, email, minLength, requiredIf, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import IsLoading from '@/components/IsLoading.vue'
import Multiselect from '@vueform/multiselect'
import ProfileImageCapture from '@/components/forms/ProfileImageCapture.vue';

export default {
    setup() {
        return { v$: useVuelidate() };
    },
    components: {
        IsLoading,
        Multiselect,
        ProfileImageCapture
    },
    data(){
        return{
            isLoading: true,
            data: {
                first_name: "",
                middle_name: "",
                last_name: "",
                email: "",
                mobile: null,
                role_id: "",
                gender: null,
                password: "",
                address: "",
                local_government_id: "",
                registration_area_id: "",
                assigned_lga_id: "",
                image: "",
                is_verified: false,
            },
            genderOptions: [
                { label: 'Male', value: 'male' },
                { label: 'Female', value: 'female' },
                { label: 'Others', value: 'others' }
            ],
            mirrorData: null,
            wardOptions: []
        }
    },
    validations() {
        return {        
            data:{
                first_name: {
                    required: helpers.withMessage("First name is required", required),
                },
                last_name: {
                    required: helpers.withMessage("Last name is required", required),
                },
                email: {
                    required: helpers.withMessage("Email is required", required),
                    email: helpers.withMessage("Please enter valid email", email),
                },
                mobile: {
                    required: helpers.withMessage("Phone number is required", required),
                },
                role_id: {
                    required: helpers.withMessage("User role is required", required),
                },
                password: {
                    required: helpers.withMessage("Password is required", required),
                    minLength: helpers.withMessage("minimum of 6 characters", minLength(6))
                }, 
                assigned_lga_id: {
                    requiredIf: requiredIf(this.enableLgaAssignment),
                },                       
            }
        }
    },
    computed:{
        roleOptions() {
            return this.$store.state.userList.roles
        },
        lgaOptions(){
            return this.$store.state.localGovernments
        },
        enableLgaAssignment(){
           let role = this.roleOptions.find(i => i.id == this.data.role_id)
           if(role){
            return ['staff'].includes(role.name.toLowerCase())
           }
           return false
        },
    },
    methods:{
        create() {
            this.v$.$validate().then(result =>{
                if (!result) { this.alertError(this.v$.$errors[0].$message); return };
                let formData = new FormData();
                formData.append('data', JSON.stringify(this.data))
                this.$store.dispatch("changeLoaderValue", true)
                this.$http.post('/users/create', formData)
                .then((response) => {
                    this.$store.dispatch("changeLoaderValue", false)
                    if(response.data.success){
                        this.$store.commit("userList/ADD_USER", response.data.data)
                        this.resetForm();
                    }
                })
            })
        },
        lgaChange(){
            if(!this.data.local_government_id){ return }
            this.data.registration_area_id = "";
            this.wardOptions = [];
            this.isLoading = true;
            this.$http.get(`${this.absoluteUrl()}/api/registration-areas?local_government_id=${this.data.local_government_id}`)
                .then((response) => {
                this.isLoading = false
                if(response.data.success){
                    this.wardOptions = response.data.data
                }
            })
        },
        getOptionData(){
            this.isLoading = true;
            this.$store.dispatch("userList/fetchRoles")
            this.$store.dispatch('localGovernments')
            .then(response => this.isLoading = false );
        },
        resetForm(){
            this.data = {...this.mirrorData}
            this.v$.$reset()
            this.$emit("closeMe")
        }
    },
    mounted(){
        this.getOptionData()
        this.mirrorData = JSON.parse(JSON.stringify(this.data))
    }
}
</script>