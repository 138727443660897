const options = {
  truncate(value, limit) {
    if(!value) return;
    if(value.length > limit){
      return value.substring(0, limit)+' ...'
    }else{
      return value
    }
  },
  truncate_start(value, limit) {
    if(!value) return;
    if(value.length > limit){
      return '...'+value.substring(value.length, value.length - limit)
    }else{
      return value
    }
  },
  slug(value) {
    if (!value) return null
    return value.toString().normalize('NFD').replace(/[\u0300-\u036f]/g,'').replace(/\s+/g,'-').toLowerCase().replace(/&/g,'-and-').replace(/[^a-z0-9\-]/g,'').replace(/-+/g,'-').replace(/^-*/,'').replace(/-*$/,'');
  },
  textFromSlug(value){
    if (!value) return '';
    let words = value.split('_');
    let formattedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
    return formattedWords.join(' ');
  },
  capitalize (value) {
    if (!value) return ''
    value = value.toString()
    let arr = value.split(" ")
    let capitalized_array = []
    arr.forEach((word) => {
      let capitalized = word.charAt(0).toUpperCase() + word.slice(1)
      capitalized_array.push(capitalized)
    })
    return capitalized_array.join(" ");
  },
  calculateAge(dobDate) {
    if (!dobDate) { return null; }
    const today = new Date();
    let age = today.getFullYear() - dobDate.getFullYear();
    const monthDiff = today.getMonth() - dobDate.getMonth();

    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dobDate.getDate())) {
        age--;
    }
    return age;
  },
  maskString(input, revealCount = 3) {
    if(!input) return;

    const str = input.toString();
    if (revealCount < 0 || revealCount > str.length) {
      return "*";
    }
    const masked = '*'.repeat(str.length - revealCount) + str.slice(-revealCount);
    return masked;
  },
  chunkArray(array, chunkSize) {
    const chunks = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
  },
  evaluateBMI(value){
    let list = [
      { from: 0, to: 18.5, title: 'Underweight', color: '#bc2020' },
      { from: 18.5, to: 25, title: 'Normal', color: '#008137' },
      { from: 25, to: 30, title: 'Overweight', color: '#ffe400' },
      { from: 30, to: Infinity, title: 'Obesity', color: '#bc2020' }
    ];
    for (let i = 0; i < list.length; i++) {
        if (value >= list[i].from && value < list[i].to) {
          let range = list[i];
          return `<span style="background:${range.color}24; color:${range.color}; padding:0px 5px 2px 5px;line-height:1; border-radius:4px;">${range.title}</span>`
        }
    }
    return '';
  },
  time(value, is24HrFormat = false) {
    if(value) {
      const date = new Date(Date.parse(value));
      let hours = date.getHours();
      const min = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes()
      if(!is24HrFormat) {
        const time = hours > 12 ? 'AM' : 'PM';
        hours = hours % 12 || 12;
        return hours + ':' + min + ' ' + time
      }
      return hours + ':' + min
    }
  },
  calculateDeliveryTime(time){
    if(!time){return '0 day'}
    return (time > 24) ? Math.round(time/24) + ' days' : '1 day'
  },
  getPercent(val, total){
    return Math.round((parseFloat(val) * 100) / parseFloat(total)) || 0
  },
  date(value, index = 0) {
    if(!value) return;
    var options = [
      { year:'numeric', month:'short', day:'2-digit' },
      { year:'2-digit', month:'short', day:'2-digit', hour: '2-digit', minute:'2-digit' },
      { weekday:'long', year:'numeric', month:'short', day:'2-digit', hour: '2-digit', minute:'2-digit' },
    ];
    var givenDate  = new Date(value);
    return new Intl.DateTimeFormat('en-US', options[index]).format(givenDate)
  },
  month_year(value) {
    var options = {month: 'short', year: 'numeric'};
    var givenDate  = new Date(value);
    if(!value || givenDate == 'Invalid Date'){return;}
    return givenDate.toLocaleString("en-US", options);
  },
  month(val, showYear = true) {
    val = String(val)
    const regx = /\w+\s(\w+)\s\d+\s(\d+)./;
    if(!showYear) {
      return regx.exec(val)[1];
    }else {
      return regx.exec(val)[1] + ' ' + regx.exec(val)[2];
    }
  },
  csv(value) {
    return value.join(', ')
  },
  filter_tags(value) {
    return value.replace(/<\/?[^>]+(>|$)/g, "")
  },
  k_formatter(num) {
    return num > 999 ? (num/1000).toFixed(1) + 'k' : num
  },
  money_format(number, decimal = 2) {
    let i = parseFloat(number); if(isNaN(i)) {return;}
    return i.toFixed(decimal).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  },
  number_format(number, decimal = 0) {
    let i = parseFloat(number); if(isNaN(i)) {return;}
    return i.toFixed(decimal).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  },
  deliveryDate(startDate, hrs){
    if(!startDate || !hrs) return;
    let option = { year:'2-digit', month:'short', day:'2-digit'}
    var givenDate  = new Date().setTime(new Date(startDate).getTime() + (parseInt(hrs)*60*60*1000));
    return new Intl.DateTimeFormat('en-US', option).format(givenDate)
  },
  convertBytesToMB(bytes) {
    if(!bytes) return 0;
    const bytesPerMB = 1024 * 1024;
    return Math.round(bytes / bytesPerMB);
  },
  getFileExtension(name) {
    if(!name) return "";
    const fileName = name;
    const extension = fileName.split('.').pop();
    return extension.toLowerCase();
  },
  getReadableFileSize(size) {
    if(!size) return "";
    const units = ['bytes', 'KB', 'MB', 'GB', 'TB'];
    let index = 0;
    let readableSize = size;
    while (readableSize >= 1024 && index < units.length - 1) {
        readableSize /= 1024;
        index++;
    }
    return `${readableSize.toFixed(2)} ${units[index]}`;
  },
  fileReader(file) {
    return new Promise((resolve, reject) => {
      if (typeof FileReader !== 'function') {
        reject('Sorry, FileReader API not supported')
      }
      var reader = new FileReader();
      reader.onload = function(event) {
        var img = new Image();
        img.onload = function() {
          var height = img.naturalHeight;
          var width = img.naturalWidth;
          resolve({
            height: height,
            width: width,
            dataURL: event.target.result
          });
        };
        img.src = event.target.result;
      };
      reader.onerror = function(error) {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  },
  base64ToBlob(base64, mimeType) {
    const byteString = atob(base64.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeType });
  },
  patchUpdatables(data, filter) {
    if(!filter){ return data }
    let allowed = Object.keys(data);
    const filteredData =  Object.keys(filter || {})
      .filter(key => allowed.includes(key))
      .reduce((obj, key) => {
          obj[key] = filter[key];
          return obj;
      }, {});
    return JSON.parse(JSON.stringify(filteredData))
  },
  date_ago(utctime, index = 0) {
    const iptDate = new Date(utctime);
    const curDate = new Date();
    const timeD = curDate - iptDate;
    const millisecsPerSec = 1000;
    const millisecsPerMin = 60 * millisecsPerSec;
    const millisecsPerHr = 60 * millisecsPerMin;
    const millisecsPerDay = 24 * millisecsPerHr;

    if (timeD < millisecsPerDay) {
      const daysAgo = Math.floor(timeD / millisecsPerDay);
      const hrsAgo = Math.floor((timeD % millisecsPerDay) / millisecsPerHr);
      const minsAgo = Math.floor((timeD % millisecsPerHr) / millisecsPerMin);
      const secsAgo = Math.floor((timeD % millisecsPerMin) / millisecsPerSec);

      if (daysAgo > 0) {
        return `${daysAgo} day${daysAgo > 1 ? 's' : ''} ago`;
      } else if (hrsAgo > 0) {
        return `${hrsAgo} hr${hrsAgo > 1 ? 's' : ''} ago`;
      } else if (minsAgo > 0) {
        return `${minsAgo} min${minsAgo > 1 ? 's' : ''} ago`;
      } else if (secsAgo > 0) {
        return `${secsAgo} sec${secsAgo > 1 ? 's' : ''} ago`;
      } else {
        return "just now";
      }
    } else {
      const options = [
        { year:'2-digit', month:'short', day:'2-digit', hour: '2-digit', minute:'2-digit' },
        { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' }
      ];
      return new Intl.DateTimeFormat('en-US', options[index]).format(iptDate)
    }
  },
}

export default {
  install: (app) => {
    app.config.globalProperties.$filters = options
    // app.provide('$filters', options)
  }
}